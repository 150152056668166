import { render, staticRenderFns } from "./Table.vue?vue&type=template&id=d2f3dfe2&scoped=true&lang=pug&"
import script from "./Table.vue?vue&type=script&lang=ts&"
export * from "./Table.vue?vue&type=script&lang=ts&"
import style0 from "./Table.vue?vue&type=style&index=0&id=d2f3dfe2&prod&lang=scss&"
import style1 from "./Table.vue?vue&type=style&index=1&id=d2f3dfe2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2f3dfe2",
  null
  
)

export default component.exports